import { type SequenceTag } from '@ghostmonitor/recartapis'
import { type QueryFunction, type QueryKey, useQuery } from '@tanstack/react-query'
import type moment from 'moment/moment'
import { useContext } from 'react'
import { queryClient } from '../../components/app/query-client'
import {
  getDefaultTimeFrameFilter,
  TimeFrameFilterContext
} from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { type SequenceStatsById } from '../../types/sequence-stat-by-id.type'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

interface SequencesStatQueryParams {
  startDate?: string
  endDate?: string
  tags?: SequenceTag | SequenceTag[]
}

interface SequencesStatProps {
  isEnabled: boolean
  tags?: SequenceTag | SequenceTag[]
}

function getQueryKey(queryParams: SequencesStatQueryParams): QueryKey {
  return [QUERY_KEY.sequencesStat, queryParams]
}

function buildQuery(
  dateFrom: moment.Moment | null,
  dateTo: moment.Moment | null,
  defaultQueryParams: Partial<SequencesStatQueryParams> = {}
): SequencesStatQueryParams {
  const queryParams: SequencesStatQueryParams = {
    ...defaultQueryParams
  }

  if (dateFrom) {
    queryParams.startDate = dateFrom.toISOString()
  }

  if (dateTo) {
    queryParams.endDate = dateTo.toISOString()
  }

  return queryParams
}

function fetchSequencesStats(
  queryParams: SequencesStatQueryParams
): QueryFunction<SequenceStatsById> {
  return async () =>
    request.get<SequenceStatsById>(`statistics/sequences`, {
      params: queryParams
    })
}

function useSequencesStats(
  { isEnabled, tags }: SequencesStatProps = { isEnabled: true }
): UseResource<SequenceStatsById> {
  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)
  const queryParams = buildQuery(dateFrom, dateTo, { tags })
  const queryKey = getQueryKey(queryParams)

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: fetchSequencesStats(queryParams),
    enabled: isEnabled && dateFrom !== undefined && dateTo !== undefined
  })

  return {
    data,
    isLoading
  }
}

function prefetchSequencesStats(tags?: SequenceTag | SequenceTag[]): void {
  const { dateFrom, dateTo } = getDefaultTimeFrameFilter()
  const queryParams = buildQuery(dateFrom, dateTo, { tags })
  const queryKey = getQueryKey(queryParams)

  queryClient.prefetchQuery(queryKey, fetchSequencesStats(queryParams))
}

export function useSequencesStat(
  props: SequencesStatProps = { isEnabled: true }
): UseResource<SequenceStatsById> & { prefetch: (tags: SequenceTag | SequenceTag[]) => void } {
  const { isLoading, data } = useSequencesStats(props)

  function prefetch(tags: SequenceTag | SequenceTag[]): void {
    prefetchSequencesStats(tags)
  }

  return {
    data,
    isLoading,
    prefetch
  }
}
