import { type BlastStatus } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getBlastUIStatus } from '../../components/sms-campaign-list/utils/get-blast-ui-status'
import { selectEditorSequenceMeta } from '../../store/selectors'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

// This hook is meant to be used in Sequence Editor to poll blast status
// In lists, use useBlasts instead
export function useBlastStatus(
  blastId: string,
  refetchInterval = 3000,
  isEnabled = true
): UseResource<BlastStatus> {
  const queryKey = [QUERY_KEY.blasts, blastId]
  const isEverSaved = useSelector(selectEditorSequenceMeta)?.isEverSaved

  const { isInitialLoading, data: blast } = useQuery({
    queryKey,
    queryFn: async () => {
      return api.getBlast(blastId)
    },
    enabled: blastId !== undefined && isEverSaved && isEnabled,
    refetchInterval
  })

  return {
    data: blast ? getBlastUIStatus(blast) : undefined,
    isLoading: isInitialLoading
  }
}
