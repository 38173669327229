import type { RotateWebhookSecretResponse } from '@ghostmonitor/recartapis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { request } from '../../utils/request'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_KEY } from './query-keys'
import { useSite } from './use-site'

export function useWebhookSecrets() {
  const queryClient = useQueryClient()
  const { data: site, isLoading } = useSite()
  const queryKey = [QUERY_KEY.rotateWebhookSecret]
  const queryKeySite = useMemo(() => [QUERY_KEY.site], [])
  const { handleError, handlePatch } = useMutationHelpers(queryKey)

  function rotateWebhookSecret() {
    return request.post<RotateWebhookSecretResponse>(`site/rotate-webhook-secret`)
  }

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryKeySite)
  }, [queryClient, queryKeySite])

  const createWebhookSecretMutation = useMutation({
    mutationFn: rotateWebhookSecret,
    onMutate: handlePatch,
    onSettled: handleSettled,
    onError: handleError
  })

  const webhookSecrets = site?.webhookSecrets?.filter((secret) => {
    if (!secret.expiresAt) {
      return true
    }

    return new Date(secret.expiresAt) > new Date()
  })

  return {
    data: webhookSecrets ?? [],
    isLoading,
    rotateWebhookSecret: createWebhookSecretMutation.mutateAsync
  }
}
