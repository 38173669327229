import { type Stat } from '@ghostmonitor/recartapis'
import { useCallback, useContext } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { TimeFrameFilterContext } from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { api } from '../../utils/api'
import { type UseResourceCache } from '../types/use-resource-cache.type'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export interface SequenceItemsStatsById {
  [sequenceItemId: string]: Stat
}

export function useSequenceItemsStat(
  sequenceId: string,
  enabled?: boolean
): UseResource<SequenceItemsStatsById> {
  const queryKey = [QUERY_KEY.sequenceItemsStat, sequenceId]
  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)

  const queryParams: {
    sequenceId: string
    startDate?: string
    endDate?: string
  } = {
    sequenceId
  }

  if (dateFrom) {
    queryParams.startDate = dateFrom.toISOString()
    queryKey.push(dateFrom.toISOString())
  }

  if (dateTo) {
    queryParams.endDate = dateTo.toISOString()
    queryKey.push(dateTo.toISOString())
  }

  const {
    isInitialLoading,
    data: sequenceItemsStat,
    isError
  } = useQuery({
    queryKey,
    queryFn: async () => api.getSequenceItemsStat(queryParams),
    enabled: sequenceId !== undefined && enabled
  })

  return {
    data: sequenceItemsStat,
    isLoading: isInitialLoading,
    isError
  }
}

export function useSequenceItemsStatCache(sequenceId: string): UseResourceCache {
  const queryClient = useQueryClient()

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries([QUERY_KEY.sequenceItemsStat, sequenceId])
  }, [queryClient, sequenceId])

  return {
    invalidateCache
  }
}
