import { SequenceTag, type Stat } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { TimeFrameFilterContext } from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useAutomatedSMSStat(): UseResource<Stat> {
  const { dateFrom: timeFrameFilterDateFrom, dateTo: timeFrameFilterDateTo } =
    useContext(TimeFrameFilterContext)

  const queryParams: {
    startDate?: string
    endDate?: string
    tags?: string[]
  } = { tags: [SequenceTag.AUTOMATED] }

  if (timeFrameFilterDateFrom) {
    queryParams.startDate = timeFrameFilterDateFrom.toISOString()
  }

  if (timeFrameFilterDateTo) {
    queryParams.endDate = timeFrameFilterDateTo.toISOString()
  }

  const queryKey = [QUERY_KEY.smsStat, queryParams]
  const {
    isInitialLoading,
    data: automatedSMSStat,
    isError
  } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<Stat>('statistics/sms', {
        params: queryParams
      })
    },
    enabled: true
  })

  return {
    data: automatedSMSStat,
    isLoading: isInitialLoading,
    isError
  }
}
