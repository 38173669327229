import {
  type PatchSubdomainsRequest,
  type Site,
  type SiteSubdomain
} from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo } from 'react'
import { api } from '../../utils/api'
import { createScope } from '../../utils/logger/logger'
import { Notification } from '../../utils/notification/notification.util'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

const logger = createScope('dashboard')

async function patch(payload: PatchSubdomainsRequest) {
  await api.patchSubdomains(payload)
}

export function useSubdomains(): UseResource<SiteSubdomain, PatchSubdomainsRequest> {
  const queryKey = useMemo(() => [QUERY_KEY.subdomains], [])
  const {
    isLoading,
    data: site,
    isError
  } = useQuery({
    queryKey,
    queryFn: () => api.getSite(['urlGenerationSettings', 'subdomain'])
  })

  const queryClient = useQueryClient()
  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryKey)
    queryClient.invalidateQueries([QUERY_KEY.site])
  }, [queryClient, queryKey])

  const handlePatchError = useCallback((err, _updatedSubdomain, rollback: () => void) => {
    logger.error(err)
    rollback()
    Notification.error('Updating subdomain settings failed. Please try again or contact support.')
  }, [])

  const handlePatchMutate = useCallback(
    (patchPayload: PatchSubdomainsRequest) => {
      queryClient.cancelQueries(queryKey)

      const subdomainsQuery = queryClient.getQueryCache().find<Site>(queryKey)
      const previousSite = cloneDeep(subdomainsQuery.state.data)
      const { subdomain } = patchPayload
      const updatedData: Site = {
        ...previousSite,
        urlGenerationSettings: {
          ...previousSite.urlGenerationSettings,
          subdomain: {
            history: Array.from(new Set([...(previousSite.subdomain?.history ?? []), subdomain])),
            current: subdomain
          }
        }
      }

      queryClient.setQueryData(queryKey, { data: updatedData })

      return () => queryClient.setQueryData(queryKey, previousSite)
    },
    [queryClient, queryKey]
  )

  const { mutateAsync: patchSubdomains } = useMutation<void, unknown, PatchSubdomainsRequest>({
    mutationFn: patch,
    onMutate: handlePatchMutate,
    onSettled: handleSettled,
    onError: handlePatchError
  })

  return {
    isLoading,
    data: site?.data?.urlGenerationSettings?.subdomain,
    isError,
    patch: patchSubdomains
  }
}
