import { type GetProductVariantsResponse } from '@ghostmonitor/recartapis'
import { useInfiniteQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { QUERY_KEY } from './query-keys'

interface Params {
  name: string
  limit: number
  offset?: number
}

export function useProductVariants(queryParams?: Params) {
  async function fetchProductVariants({ pageParam = 0 }): Promise<GetProductVariantsResponse> {
    const params = {
      ...queryParams,
      offset: pageParam
    }

    return request.get<GetProductVariantsResponse>('product-variants', { params })
  }

  const queryKey = [QUERY_KEY.productVariants, queryParams?.name]

  const { isLoading, data, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: fetchProductVariants,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.length < queryParams.limit || lastPage.data.length === 0) {
        return undefined
      }

      return pages.flatMap((page) => page.data).length
    }
  })

  return {
    isLoading,
    data: data?.pages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  }
}
