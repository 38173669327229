import { type PublicFontConfig, type SiteFontConfig } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useOptinToolFonts(): UseResource<(PublicFontConfig | SiteFontConfig)[]> {
  const queryKey = [QUERY_KEY.optinToolFonts]

  const {
    data: optinToolFontsData,
    isLoading,
    error
  } = useQuery({
    queryKey,
    queryFn: api.getOptinToolFonts
  })

  return {
    data: optinToolFontsData?.data,
    error,
    isLoading
  }
}
