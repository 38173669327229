import { type SMSConversations } from '@ghostmonitor/recartapis'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { selectSiteId } from '../../store/slices/site/site.selectors'
import { setLocalStorageItem } from '../../utils/set-local-storage-item'
import { useSiteSelector } from '../use-site-selector'
import { QUERY_KEY } from './query-keys'
import { type UseSMSConversation, useSMSConversation } from './use-sms-conversation'

export function getSMSSentMessagesLengthKey(siteId: string, phoneNumber: string): string {
  return `sms-sent-messages-length-${siteId}-${phoneNumber}`
}

export function getSMSSentMessagesKey(siteId: string, phoneNumber: string): string {
  return `sms-sent-messages-${siteId}-${phoneNumber}`
}

export function useSMSConversationWithOptimisticSending(
  phoneNumber: string | undefined
): UseSMSConversation {
  const queryKey = [QUERY_KEY.smsConversations, phoneNumber]
  const { sendMessage, data: conversation, isLoading } = useSMSConversation(phoneNumber)
  const siteId = useSiteSelector(selectSiteId)
  const keySMSSentMessagesLength = getSMSSentMessagesLengthKey(siteId, phoneNumber)
  const keySMSSentMessages = getSMSSentMessagesKey(siteId, phoneNumber)
  const queryClient = useQueryClient()
  const [sentMessagesLength, setSentMessagesLength] = useState(0)

  function getLocalstorageSentMessagesLength(): number {
    const rawValue = localStorage.getItem(keySMSSentMessagesLength)

    if (rawValue === null) {
      return 0
    }
    return JSON.parse(rawValue)
  }

  function setLocalstorageSentMessagesLength(length: number): void {
    setLocalStorageItem(keySMSSentMessagesLength, JSON.stringify(length))
  }

  function incrementSentMessagesLength(): void {
    setSentMessagesLength((sentMessagesLength) => {
      setLocalstorageSentMessagesLength(sentMessagesLength + 1)
      return sentMessagesLength + 1
    })
  }

  function getOptimisticSentMessages(): SMSConversations.SmsConversationMessage[] {
    const rawValue = localStorage.getItem(keySMSSentMessages)
    if (rawValue === null) {
      return []
    }
    return JSON.parse(rawValue)
  }

  function setOptimisticSentMessages(messages: SMSConversations.SmsConversationMessage[]): void {
    setLocalStorageItem(keySMSSentMessages, JSON.stringify(messages))
  }

  function appendOptimisticSentMessages(message: SMSConversations.SmsConversationMessage) {
    const messages = getOptimisticSentMessages()
    messages.push(message)
    setOptimisticSentMessages(messages)
  }

  function filterManualSentMessages(messages: SMSConversations.SmsConversationMessage[]) {
    return messages.filter(
      (message) => message.direction === 'out' && message.isAutomatic === false
    )
  }

  async function optimisticSendMessage(text: string) {
    await sendMessage(text)
    appendOptimisticSentMessages({
      direction: 'out',
      isAutomatic: false,
      isSuccessful: true,
      date: null,
      hasAttachment: false,
      text,
      type: 'automatic',
      smsThreadId: '',
      flowItemId: ''
    })
    incrementSentMessagesLength()
    // So the conversations will be recalculated forcefully
    queryClient.invalidateQueries(queryKey)
  }

  useEffect(() => {
    const localstorageSentMessagesLength = getLocalstorageSentMessagesLength()
    if (
      conversation?.length > 0 &&
      localstorageSentMessagesLength === 0 &&
      phoneNumber !== undefined
    ) {
      const manualMessagesLength = filterManualSentMessages(conversation).length
      setSentMessagesLength(manualMessagesLength)
      setLocalstorageSentMessagesLength(manualMessagesLength)
    }
  }, [conversation, phoneNumber])

  const conversationWithOptimisticMessages = useMemo(() => {
    if (phoneNumber === undefined) {
      return []
    }
    const frontendSentMessagesLength = sentMessagesLength
    const backendSentMessagesLength = filterManualSentMessages(conversation ?? []).length

    if (frontendSentMessagesLength > backendSentMessagesLength) {
      return [...(conversation ?? []), ...getOptimisticSentMessages()]
    } else {
      setOptimisticSentMessages([])
      setLocalstorageSentMessagesLength(backendSentMessagesLength)
      setSentMessagesLength(backendSentMessagesLength)
      return conversation
    }
  }, [conversation, phoneNumber, sentMessagesLength])

  return {
    data: conversationWithOptimisticMessages,
    isLoading,
    sendMessage: optimisticSendMessage
  }
}
