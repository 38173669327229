import { type GetProductsResponse } from '@ghostmonitor/recartapis'
import { useInfiniteQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { QUERY_KEY } from './query-keys'

interface Params {
  name: string
  limit: number
  offset?: number
}

export function useProducts(queryParams?: Params) {
  async function fetchProducts({ pageParam = 0 }): Promise<GetProductsResponse> {
    const params = {
      ...queryParams,
      offset: pageParam
    }

    return request.get<GetProductsResponse>('products', { params })
  }

  const queryKey = [QUERY_KEY.products, queryParams?.name]

  const { isLoading, data, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: fetchProducts,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.length < queryParams.limit || lastPage.data.length === 0) {
        return undefined
      }

      return pages.flatMap((page) => page.data).length
    }
  })

  return {
    isLoading,
    data: data?.pages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  }
}
