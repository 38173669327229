import type {
  CreateApiKeyRequest,
  CreateApiKeyResponse,
  ListApiKeyResponse
} from '@ghostmonitor/recartapis'
import { useMutation, useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_KEY } from './query-keys'

export function useApiKeys() {
  const queryKey = [QUERY_KEY.apiKeys]
  const { handleError, handleSettled, handleRemove, handlePatch } = useMutationHelpers(queryKey)

  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => request.get<ListApiKeyResponse>(`apikeys`)
  })

  function createApiKey(newApiKey: CreateApiKeyRequest) {
    return request.post<CreateApiKeyResponse>(`apikeys`, newApiKey)
  }

  const createApiKeyMutation = useMutation({
    mutationFn: createApiKey,
    onMutate: handlePatch,
    onSettled: handleSettled,
    onError: handleError
  })

  function deleteApiKey(apiKeyId: string) {
    return request.del(`apikeys/${apiKeyId}`)
  }

  const deleteApiKeyMutation = useMutation({
    mutationFn: deleteApiKey,
    onMutate: handleRemove,
    onSettled: handleSettled,
    onError: handleError
  })

  return {
    data: data?.data,
    isLoading,
    error,
    createApiKey: createApiKeyMutation.mutateAsync,
    deleteApiKey: deleteApiKeyMutation.mutateAsync
  }
}
