import { useCallback, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { type UseResourceCache } from '../types/use-resource-cache.type'
import { QUERY_KEY } from './query-keys'

export function useSequencesCache(queryParams?: Record<string, unknown>): UseResourceCache {
  const queryClient = useQueryClient()
  const queryKey = useMemo(() => [QUERY_KEY.sequences, queryParams], [queryParams])

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries(queryKey)
  }, [queryClient, queryKey])

  return {
    invalidateCache
  }
}
