import type { EditorField, GeneralStyle, OptinToolShadow, Popup } from '@ghostmonitor/recartapis'
import isEmpty from 'lodash/isEmpty'
import isObjectLike from 'lodash/isObjectLike'
import { type ResolvableSettings } from './style-resolver'

export type ResettableSettings = EditorField | OptinToolShadow

export function resetSetting(settings: ResettableSettings, generalStyle: GeneralStyle): void {
  for (const key in settings) {
    if (
      generalStyle[key] === undefined ||
      (key === 'type' && !['web-safe', 'google', 'custom'].includes(settings[key]))
    ) {
      continue
    }

    if (generalStyle[key] === null || !isObjectLike(settings[key])) {
      delete settings[key]
    }

    resetSetting(settings[key], generalStyle[key])

    if (isEmpty(settings[key])) {
      delete settings[key]
    }
  }
}

function resetSettingByGeneralStyle(
  settings: ResolvableSettings,
  generalStyle: GeneralStyle
): void {
  if (
    typeof settings !== 'object' ||
    settings === null ||
    (Array.isArray(settings) && settings.length === 0) ||
    ('generalStyleSlug' in settings && settings.generalStyleSlug !== generalStyle.slug)
  ) {
    return
  }

  for (const key in settings) {
    if (settings[key] && settings[key].generalStyleSlug === generalStyle.slug) {
      resetSetting(settings[key], generalStyle)
    } else {
      resetSettingByGeneralStyle(settings[key], generalStyle)
    }
  }
}

export function resetAllSettingsByGeneralStyle(popup: Popup, generalStyle: GeneralStyle): void {
  for (const step of popup.steps) {
    resetSettingByGeneralStyle(popup.settings[step], generalStyle)
  }
}
