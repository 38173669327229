import { type GetBackfillsResponse } from '@ghostmonitor/recartapis'
import { type Backfills } from '@ghostmonitor/recartapis/clients/cjs/site/site'
import { useQuery } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

const IMPORTING = 'importing'

export function useBackfill(): UseResource<Backfills> & { isBackfillInProgress: boolean } {
  const queryKey = [QUERY_KEY.backfills]

  function getHasBackfillInProgress(data: Backfills): boolean {
    return data?.order?.status === IMPORTING || data?.product?.status === IMPORTING
  }

  const { isLoading, data, error } = useQuery<GetBackfillsResponse, AxiosError>({
    queryKey,
    queryFn: async () => {
      return request.get<GetBackfillsResponse>(`site/backfills`)
    },
    refetchInterval: (data) => {
      const hasBackfillInProgress = getHasBackfillInProgress(data?.data)
      if (hasBackfillInProgress) {
        return 5000
      } else {
        return false
      }
    }
  })

  return {
    isLoading,
    data: data?.data,
    isBackfillInProgress: getHasBackfillInProgress(data?.data),
    error
  }
}
