import { createSlice } from '@reduxjs/toolkit'
import {
  type AppIntegration,
  IntegrationSlug,
  requestInitialMeta,
  type RequestMeta
} from '../../../routes/integrations/integrations.type'
import { selectIntegrationMeta } from './integrations.selectors'
import {
  connectAppIntegrationThunk,
  deleteAppIntegrationThunk,
  fetchAppIntegrationThunk,
  updateAppIntegrationThunk
} from './integrations.thunks'

export interface IntegrationMeta {
  loadConfig: RequestMeta
  connect?: RequestMeta
}

export interface IntegrationsState {
  integrationsBySlug: {
    [integrationName: string]: AppIntegration | null
  }
  integrationsMetaBySlug: {
    [integrationName: string]: IntegrationMeta
  }
}

const integrationSlugs = [
  IntegrationSlug.KLAVIYO,
  IntegrationSlug.SMSBUMP,
  IntegrationSlug.DRIP,
  IntegrationSlug.POSTSCRIPT,
  IntegrationSlug.OMNISEND,
  IntegrationSlug.ATTENTIVE,
  IntegrationSlug.GORGIAS,
  IntegrationSlug.ACTIVE_CAMPAIGN,
  IntegrationSlug.RECHARGE,
  IntegrationSlug.SENDLANE,
  IntegrationSlug.YOTPO,
  IntegrationSlug.YOTPO_REVIEW,
  IntegrationSlug.LOYALTY_LION,
  IntegrationSlug.BOLD,
  IntegrationSlug.NETCORE_CLOUD
]

function getInitialState(): IntegrationsState {
  const integrationsBySlug = {}
  const integrationsMetaBySlug = {}

  integrationSlugs.forEach((integrationSlug) => {
    integrationsBySlug[integrationSlug] = null
    integrationsMetaBySlug[integrationSlug] = {
      loadConfig: { ...requestInitialMeta },
      connect: { ...requestInitialMeta }
    }
  })

  return {
    integrationsBySlug,
    integrationsMetaBySlug
  }
}

export const integrationsInitialState: IntegrationsState = getInitialState()

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: integrationsInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchAppIntegrationThunk.pending, (state, action) => {
        const { integrationSlug } = action.meta.arg

        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)
        integrationMeta.loadConfig.isLoading = true
        integrationMeta.loadConfig.error = null
      })
      .addCase(fetchAppIntegrationThunk.fulfilled, (state, action) => {
        const { integrationSlug } = action.meta.arg
        if (action.payload) {
          state.integrationsBySlug[integrationSlug] = action.payload
        }
        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)
        integrationMeta.loadConfig.isLoading = false
      })
      .addCase(fetchAppIntegrationThunk.rejected, (state, action) => {
        const { integrationSlug } = action.meta.arg

        state.integrationsBySlug[integrationSlug] = null
        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)
        integrationMeta.loadConfig.error = action.error
        integrationMeta.loadConfig.isLoading = false
      })

      .addCase(updateAppIntegrationThunk.pending, (state, action) => {
        const { integrationSlug } = action.meta.arg

        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)
        integrationMeta.loadConfig.isLoading = true
        integrationMeta.loadConfig.error = null
      })
      .addCase(updateAppIntegrationThunk.fulfilled, (state, action) => {
        const { integrationSlug } = action.meta.arg

        state.integrationsBySlug[integrationSlug] = action.payload
        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)
        integrationMeta.loadConfig.isLoading = false
      })
      .addCase(updateAppIntegrationThunk.rejected, (state, action) => {
        const { integrationSlug } = action.meta.arg

        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)
        integrationMeta.loadConfig.error = action.error
        integrationMeta.loadConfig.isLoading = false
      })

      .addCase(connectAppIntegrationThunk.pending, (state, action) => {
        const { integrationSlug } = action.meta.arg
        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)

        if (!integrationMeta.connect) {
          throw new Error('IntegrationMeta.connect is not defined')
        }

        integrationMeta.connect.isLoading = true
        integrationMeta.connect.error = null
      })
      .addCase(connectAppIntegrationThunk.fulfilled, (state, action) => {
        const { integrationSlug } = action.meta.arg
        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)

        if (!integrationMeta.connect) {
          throw new Error('IntegrationMeta.connect is not defined')
        }

        integrationMeta.connect.isLoading = false
      })
      .addCase(connectAppIntegrationThunk.rejected, (state, action) => {
        const { integrationSlug } = action.meta.arg
        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)

        if (!integrationMeta.connect) {
          throw new Error('IntegrationMeta.connect is not defined')
        }

        integrationMeta.connect.error = action.error
        integrationMeta.connect.isLoading = false
      })
      .addCase(deleteAppIntegrationThunk.pending, (state, action) => {
        const { integrationSlug } = action.meta.arg

        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)
        integrationMeta.loadConfig.isLoading = true
        integrationMeta.loadConfig.error = null
      })
      .addCase(deleteAppIntegrationThunk.fulfilled, (state, action) => {
        const { integrationSlug } = action.meta.arg

        state.integrationsBySlug[integrationSlug] = null
        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)
        integrationMeta.loadConfig.isLoading = false
      })
      .addCase(deleteAppIntegrationThunk.rejected, (state, action) => {
        const { integrationSlug } = action.meta.arg

        const integrationMeta = selectIntegrationMeta(integrationSlug)(state)
        integrationMeta.loadConfig.error = action.error
        integrationMeta.loadConfig.isLoading = false
      })
})

export const { reducer: integrationsReducer } = integrationsSlice
