import { type HttpResponse, type SubscriberCleanup } from '@ghostmonitor/recartapis'
import { useMutation, useQuery } from '@tanstack/react-query'
import orderBy from 'lodash/orderBy'
import { request } from '../../utils/request'
import { QUERY_KEY } from './query-keys'

export function useSubscriberCleanup() {
  const queryKey = [QUERY_KEY.subscriberCleanups]
  const {
    data: subscriberCleanupData,
    isLoading: isSubscriberCleanupLoading,
    error: subscriberCleanupError,
    refetch
  } = useQuery({
    queryKey,
    queryFn: () => request.get<HttpResponse<SubscriberCleanup[]>>('site/subscriber-cleanups'),
    keepPreviousData: true,
    refetchInterval: (data) => {
      const hasRunningCleanup = data?.data.some((cleanup) => cleanup.status !== 'completed')
      return hasRunningCleanup ? 5000 : null
    }
  })

  const {
    mutateAsync: createSubscriberCleanup,
    isLoading: isCreatingSubscriberCleanup,
    error: createSubscriberCleanupError
  } = useMutation({
    mutationFn: () => {
      return request.post('site/subscriber-cleanups')
    },
    onSuccess: () => {
      refetch() // Trigger a refetch after creating a new subscriber cleanup
    }
  })

  const sortedSubscriberCleanups = orderBy(
    subscriberCleanupData?.data,
    (cleanup) => new Date(cleanup.createdAt),
    'desc'
  )

  return {
    subscriberCleanups: sortedSubscriberCleanups,
    isSubscriberCleanupLoading,
    subscriberCleanupError,
    createSubscriberCleanup,
    isCreatingSubscriberCleanup,
    createSubscriberCleanupError
  }
}
