import {
  type PatchSegmentRequest,
  type Segment,
  type SegmentListResponse,
  type SegmentListView
} from '@ghostmonitor/recartapis'
import { useCallback, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient, type UseQueryOptions } from '@tanstack/react-query'
import { createScope } from '../../utils/logger/logger'
import { Notification } from '../../utils/notification/notification.util'
import { request } from '../../utils/request'
import { type PatchResourceArgs } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

const logger = createScope('dashboard')

async function patch({ id, payload }: PatchResourceArgs<PatchSegmentRequest>) {
  await request.patch<void>(`segments/${id}`, payload)
}

export function useSegmentsList(
  queryParams?: Record<string, unknown>,
  options?: UseQueryOptions<SegmentListResponse>
) {
  const queryClient = useQueryClient()
  const queryKey = useMemo(() => [QUERY_KEY.segmentsList, queryParams], [queryParams])

  const { isLoading, data, isError, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<SegmentListResponse>('segments', {
        params: queryParams
      })
    },
    ...options
  })

  const handlePatchMutate = useCallback(
    async (patchPayload: PatchResourceArgs<Segment>) => {
      await queryClient.cancelQueries(queryKey)

      const previousSegmentsList: SegmentListResponse = queryClient.getQueryData(queryKey)
      const segmentsList = previousSegmentsList.data.map((segment) =>
        segment.id === patchPayload.id
          ? {
              ...segment,
              ...patchPayload.payload
            }
          : segment
      )

      queryClient.setQueryData(queryKey, { data: segmentsList })

      return { previousSegmentsList }
    },
    [queryClient, queryKey]
  )

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryKey)
  }, [queryClient, queryKey])

  const handlePatchError = useCallback(
    (err, newSegment, context) => {
      logger.error(err)
      queryClient.setQueryData(queryKey, context.previousSegmentsList)
      Notification.error('Failed to update segment. Please try again or contact support.')
    },
    [queryClient, queryKey]
  )

  const { mutateAsync: mutatePatchSegment } = useMutation<
    void,
    unknown,
    PatchResourceArgs<Partial<SegmentListView>>
  >({
    mutationFn: patch,
    onMutate: handlePatchMutate,
    onSettled: handleSettled,
    onError: handlePatchError
  })

  return {
    isLoading,
    data: data?.data,
    isError,
    refetch,
    patch: mutatePatchSegment
  }
}
