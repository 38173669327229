import {
  type SubscriberImport,
  type SubscriberImportListResponse,
  SubscriberImportStatus
} from '@ghostmonitor/recartapis'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useSubscriberImportList(segmentId?: string): UseResource<SubscriberImport[]> {
  const queryKey = [QUERY_KEY.subscriberImportList, segmentId]
  const queryClient = useQueryClient()

  function getHasImportInProgress(data: SubscriberImport[]) {
    return data?.some((item) => item.status === SubscriberImportStatus.IMPORTING)
  }

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<SubscriberImportListResponse>('subscriber-imports', {
        params: { segmentId }
      })
    },
    onSuccess: (data) => {
      const hasImportInProgress = getHasImportInProgress(data?.data)
      if (!hasImportInProgress) {
        queryClient.invalidateQueries([QUERY_KEY.subscribersOverview])
        queryClient.invalidateQueries([QUERY_KEY.subscribersStackedChart])
        queryClient.invalidateQueries([QUERY_KEY.subscribersList])
        if (segmentId) {
          queryClient.invalidateQueries([QUERY_KEY.segment, segmentId])
        }
      }
    },
    refetchInterval: (data) => {
      const hasImportInProgress = getHasImportInProgress(data?.data)
      if (hasImportInProgress) {
        return 5000
      } else {
        return false
      }
    }
  })

  return {
    isLoading,
    data: data?.data
  }
}
