import {
  type CreateCustomFontPayload,
  type HttpResponse,
  type SiteFontConfig,
  type UpdateCustomFontPayload
} from '@ghostmonitor/recartapis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { api } from '../../utils/api'
import { QUERY_KEY } from './query-keys'

export function useOptinToolFontCreate() {
  const queryKey = useMemo(() => [QUERY_KEY.optinToolFonts], [])
  const queryClient = useQueryClient()

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryKey)
  }, [queryClient, queryKey])

  // CREATE
  const {
    mutateAsync: createOptinToolFont,
    isLoading: isCreatingOptinToolFont,
    error: createOptinToolFontError
  } = useMutation({
    mutationFn: api.createOptinToolFont,
    onSettled: handleSettled
  })

  // UPDATE
  const {
    mutateAsync: updateOptinToolFont,
    isLoading: isUpdatingOptinToolFont,
    error: updateOptinToolFontError
  } = useMutation({
    mutationFn: ({ fontId, font }: { fontId: string; font: UpdateCustomFontPayload }) =>
      api.updateOptinToolFont(fontId, font),
    onSettled: handleSettled
  })

  return {
    createOptinToolFont,
    isCreatingOptinToolFont,
    createOptinToolFontError,
    updateOptinToolFont,
    isUpdatingOptinToolFont,
    updateOptinToolFontError
  }
}
