import { type SMSSettingsService } from '@ghostmonitor/recartapis'
import { type UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_KEY } from './query-keys'

interface UseLeasablePhoneNumbers {
  leasePhoneNumbers: UseMutateAsyncFunction<
    SMSSettingsService.LeasePhoneNumbersResponse,
    unknown,
    void,
    unknown
  >
  isLoading: boolean
}

export function useLeasablePhoneNumbers(): UseLeasablePhoneNumbers {
  const queryKey = [QUERY_KEY.phoneNumbers]
  const queryClient = useQueryClient()
  const { handleSettled, handleError } = useMutationHelpers(queryKey)

  const { mutateAsync: mutateLeasePhoneNumbers, isLoading } = useMutation<
    SMSSettingsService.LeasePhoneNumbersResponse,
    unknown,
    void
  >({
    mutationFn: () => api.leasePhoneNumbers(),
    onSettled: handleSettled,
    onError: handleError,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
    }
  })

  return {
    leasePhoneNumbers: mutateLeasePhoneNumbers,
    isLoading
  }
}
