import { type SMSSettingsService } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useLeasablePhoneNumbersCount(): UseResource<SMSSettingsService.GetLeasablePhoneNumbersCountResponse_LeasablePhoneNumbersCount> {
  const queryKey = [QUERY_KEY.leasablePhoneNumbersCount]

  const { data, isError, isLoading } = useQuery({
    queryKey,
    queryFn: api.getLeasablePhoneNumbersCount
  })

  return {
    data: data?.data,
    isError,
    isLoading
  }
}
