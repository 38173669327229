import { type Stat } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useMessengerStat(
  dateFrom?: string | null,
  dateTo?: string | null
): UseResource<Stat> {
  const queryKey = [QUERY_KEY.messengerStat]

  const queryParams: {
    startDate?: string
    endDate?: string
  } = {}

  if (dateFrom) {
    queryParams.startDate = dateFrom
    queryKey.push(dateFrom)
  }

  if (dateTo) {
    queryParams.endDate = dateTo
    queryKey.push(dateTo)
  }

  const {
    isInitialLoading,
    data: messengerStat,
    isError
  } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<Stat>('statistics/messenger', {
        params: queryParams
      })
    },
    enabled: dateFrom !== undefined && dateTo !== undefined
  })

  return {
    data: messengerStat,
    isLoading: isInitialLoading,
    isError
  }
}
