import { type GetProductTypesResponse } from '@ghostmonitor/recartapis'
import { useInfiniteQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { QUERY_KEY } from './query-keys'

interface Params {
  type: string
  limit: number
  offset?: number
}

export function useProductTypes(queryParams?: Params) {
  async function fetchProductTypes({ pageParam = 0 }): Promise<GetProductTypesResponse> {
    const params = {
      ...queryParams,
      offset: pageParam
    }

    return request.get<GetProductTypesResponse>('product-types', { params })
  }

  const queryKey = [QUERY_KEY.productTypes, queryParams?.type]

  const { isLoading, data, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: fetchProductTypes,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.length < queryParams.limit || lastPage.data.length === 0) {
        return undefined
      }

      return pages.flatMap((page) => page.data).length
    }
  })

  return {
    isLoading,
    data: data?.pages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  }
}
