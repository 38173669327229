import { useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback } from 'react'
import { createScope } from '../../utils/logger/logger'
import { type RemoveResourceArgs } from '../types/use-resource.type'
const logger = createScope('dashboard')

interface useMutationHelpersReturn<TResource> {
  handleUpdate: (patchPayload: Partial<TResource>) => () => void
  handlePatch: (patchPayload: TResource) => () => void
  handleRemove: () => () => void
  handleRemoveById: (removePayload: RemoveResourceArgs) => () => void
  handleSettled: () => void
  handleError: (err: unknown, _, rollback: () => void) => void
}

interface WithUnderscoreId {
  _id: string
}

interface WithPlainId {
  id: string
}

type WithId = WithUnderscoreId | WithPlainId

function isWithPlainId(idType: WithId): idType is WithPlainId {
  return (idType as WithPlainId).id !== undefined
}

// Can not be used with HttpResponse
export function useMutationHelpers<TResource>(
  queryKey: string[]
): useMutationHelpersReturn<TResource> {
  const queryClient = useQueryClient()

  const handlePatch = useCallback(
    (patchPayload: Partial<TResource>) => {
      queryClient.cancelQueries(queryKey)

      const dataQuery = queryClient.getQueryCache().find(queryKey)
      const previousData = cloneDeep(dataQuery?.state?.data as TResource) ?? {}
      queryClient.setQueryData(queryKey, {
        ...previousData,
        ...patchPayload
      })

      return () => queryClient.setQueryData(queryKey, previousData)
    },
    [queryClient, queryKey]
  )

  const handleRemove = useCallback(() => {
    queryClient.cancelQueries(queryKey)

    const dataQuery = queryClient.getQueryCache().find(queryKey)!
    const previousData = cloneDeep(dataQuery.state.data as TResource)

    queryClient.removeQueries(queryKey)

    return () => queryClient.setQueryData(queryKey, previousData)
  }, [queryClient, queryKey])

  const handleRemoveById = useCallback(
    (removePayload: RemoveResourceArgs) => {
      type RemovableResource = TResource & WithId
      queryClient.cancelQueries(queryKey)

      const dataQuery = queryClient.getQueryCache().find(queryKey)!
      const previousData = cloneDeep(dataQuery.state.data as RemovableResource[])

      const newData = previousData.filter((item) => {
        if (isWithPlainId(item)) {
          return item.id !== removePayload.id
        }
        return item._id !== removePayload.id
      })
      queryClient.setQueryData(queryKey, newData)

      return () => queryClient.setQueryData(queryKey, previousData)
    },
    [queryClient, queryKey]
  )

  const handleUpdate = useCallback(
    (updatePayload: TResource) => {
      queryClient.cancelQueries(queryKey)

      const dataQuery = queryClient.getQueryCache().find(queryKey)!
      const previousData = cloneDeep(dataQuery.state.data as TResource)
      queryClient.setQueryData(queryKey, updatePayload)

      return () => queryClient.setQueryData(queryKey, previousData)
    },
    [queryClient, queryKey]
  )

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryKey)
  }, [queryClient, queryKey])

  const handleError = useCallback((err, _, rollback: () => void) => {
    logger.error(err)
    rollback()
  }, [])

  return {
    handleUpdate,
    handlePatch,
    handleRemove,
    handleRemoveById,
    handleSettled,
    handleError
  }
}
