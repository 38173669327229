import { type SMSSettings, type SMSSettingsService } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { useMutation, useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_KEY } from './query-keys'

export type PatchSMSQuietHoursSettingRequest = Omit<
  SMSSettingsService.UpsertQuietHoursSettingsRequest,
  'siteId'
>

async function patchSMSQuietHoursSettings(
  patchPayload: PatchSMSQuietHoursSettingRequest
): Promise<void> {
  await api.patchSMSQuietHoursSettings(patchPayload)
}

export function useSMSQuietHoursSettings(
  options?: UseQueryOptions<SMSSettingsService.GetQuietHoursSettingsResponse>
): UseResource<SMSSettings.QuietHoursConfig> {
  const queryKey = [QUERY_KEY.smsQuietHoursSettings]
  const { handleError, handlePatch, handleSettled } =
    useMutationHelpers<PatchSMSQuietHoursSettingRequest>(queryKey)

  const {
    isLoading,
    data,
    error: requestError
  } = useQuery({
    queryKey,
    queryFn: api.getSMSQuietHoursSettings,
    ...options
  })
  const error: SerializedError = requestError

  const { mutateAsync: mutatePatchSMSQuietHoursSettings } = useMutation<
    void,
    unknown,
    PatchSMSQuietHoursSettingRequest
  >({
    mutationFn: patchSMSQuietHoursSettings,
    onMutate: handlePatch,
    onError: handleError,
    onSettled: handleSettled
  })

  return {
    data: data?.data,
    patch: mutatePatchSMSQuietHoursSettings,
    error,
    isLoading
  }
}
