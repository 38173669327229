import { type UpdateOptinToolExperimentPayload } from '@ghostmonitor/recartapis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { api } from '../../utils/api'
import { QUERY_KEY } from './query-keys'

export function useOptinToolExperimentCreate() {
  const queryKey = useMemo(() => [QUERY_KEY.optinToolExperiments], [])
  const queryClient = useQueryClient()

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryKey)
  }, [queryClient, queryKey])

  const {
    mutateAsync: createOptinToolExperiment,
    isLoading: isCreatingOptinToolExperiment,
    error: createOptinToolExperimentError
  } = useMutation({
    mutationFn: api.createOptinToolExperiment,
    onSettled: handleSettled
  })

  const {
    mutateAsync: updateOptinToolExperiment,
    isLoading: isUpdatingOptinToolExperiment,
    error: updateOptinToolExperimentError
  } = useMutation({
    mutationFn: ({
      experimentId,
      updatedValues
    }: {
      experimentId: string
      updatedValues: UpdateOptinToolExperimentPayload
    }) => api.updateOptinToolExperiment(experimentId, updatedValues),
    onSettled: handleSettled
  })

  return {
    createOptinToolExperiment,
    isCreatingOptinToolExperiment,
    createOptinToolExperimentError,
    updateOptinToolExperiment,
    isUpdatingOptinToolExperiment,
    updateOptinToolExperimentError
  }
}
