import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useHasUsedFacebookPage(): UseResource<any> {
  const { data, isLoading, isError } = useQuery({
    queryKey: [QUERY_KEY.hasUsedFacebookPage],
    queryFn: api.hasUsedFacebookPage
  })

  return {
    data,
    isLoading,
    isError
  }
}
