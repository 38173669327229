import { type HttpResponse, type OptinTool } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

// NOTE: prefer using useOptinToolsList
export function useOptinTools(): UseResource<OptinTool[]> {
  const queryKey = [QUERY_KEY.optinTool]

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: async () => request.get<HttpResponse<OptinTool[]>>(`optin-tools`)
  })

  return {
    isLoading,
    data: data?.data
  }
}
