import {
  type StaticDiscountCode,
  type CreateDiscountCodeResponse,
  type DiscountTypes
} from '@ghostmonitor/recartapis'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_KEY } from './query-keys'

export type CreateDiscountCodeRequest = Omit<StaticDiscountCode, 'expired' | 'priceRuleId'> & {
  siteId: string
}

const discountTypeRequestValues: { [k in DiscountTypes]: number } = {
  PERCENTAGE: 1,
  FIXED_AMOUNT: 2,
  FREE_SHIPPING: 3
}

export function useDiscountCodes(): UseResource<StaticDiscountCode[], unknown, StaticDiscountCode> {
  const queryKey = QUERY_KEY.discountCodes
  const queryClient = useQueryClient()
  const { handleError } = useMutationHelpers([QUERY_KEY.discountCodes])

  async function createDiscountCode(
    discountCodeRequest: CreateDiscountCodeRequest
  ): Promise<CreateDiscountCodeResponse> {
    return request.post<CreateDiscountCodeResponse>('discount-codes', {
      ...discountCodeRequest,
      type: discountTypeRequestValues[discountCodeRequest.type]
    })
  }

  const invalidateCache = useCallback(
    (discountCode?: string) => {
      if (discountCode) {
        queryClient.invalidateQueries([queryKey, discountCode])
      } else {
        queryClient.invalidateQueries([queryKey])
      }
    },
    [queryClient, queryKey]
  )

  const handleCreateMutate = useCallback(
    (discountCode: StaticDiscountCode) => {
      const discountCodeQueryKey = [queryKey, discountCode.discountCode]

      queryClient.setQueryData(discountCodeQueryKey, discountCode)

      // There was now discount code before creation
      return () => queryClient.setQueryData(discountCodeQueryKey, undefined)
    },
    [queryClient, queryKey]
  )

  const { mutateAsync: mutateCreateDiscountCode } = useMutation<
    CreateDiscountCodeResponse,
    unknown,
    StaticDiscountCode
  >({
    mutationFn: createDiscountCode,
    onMutate: handleCreateMutate,
    onError: handleError
  })

  return {
    data: [],
    isLoading: false,
    create: mutateCreateDiscountCode,
    invalidateCache
  }
}
