import {
  type SegmentDetailsResponse,
  type SegmentListResponse,
  type SegmentVariantUI,
  type UpdateSegmentRequest,
  type UpdateSegmentResponse
} from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { useMemo } from 'react'
import { type ExpressionUI } from '../../types/segment/condition-ui.type'
import { convertSegmentAPIToUI } from '../../types/segment/converters/api-to-ui/segment'
import { convertExpressionUIToAPI } from '../../types/segment/converters/ui-to-api/segment'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

type UpdateSegmentRequestUI = Omit<UpdateSegmentRequest, 'expression'> & {
  expression: ExpressionUI
}

export function useSegment(segmentId: string): UseResource<SegmentVariantUI> & {
  isUpdateSegmentLoading: boolean
  updateSegment: (segment: UpdateSegmentRequestUI) => Promise<UpdateSegmentResponse>
} {
  const queryClient = useQueryClient()
  const queryKey = [QUERY_KEY.segment, segmentId]

  const { isInitialLoading, data, error, refetch } = useQuery<SegmentDetailsResponse, AxiosError>({
    queryKey,
    queryFn: async () => {
      return request.get<SegmentDetailsResponse>(`segments/${segmentId}`)
    },
    enabled: segmentId !== undefined
  })

  const { mutateAsync: updateSegment, isLoading: isUpdateSegmentLoading } = useMutation<
    UpdateSegmentResponse,
    unknown,
    UpdateSegmentRequestUI
  >({
    mutationFn: (body) => {
      const segment: UpdateSegmentRequest = {
        name: body.name,
        type: body.type,
        expression: convertExpressionUIToAPI(body.expression)
      }

      return request.put<UpdateSegmentResponse>(`segments/${segmentId}`, segment)
    },
    onSuccess: (updateSegmentResponse: UpdateSegmentResponse) => {
      const segmentsList: SegmentListResponse = queryClient.getQueryData([QUERY_KEY.segmentsList])
      if (segmentsList) {
        const newSegmentsList = {
          data: segmentsList.data.map((segment) =>
            segment.id === segmentId ? updateSegmentResponse.data : segment
          )
        }

        queryClient.setQueryData([QUERY_KEY.segmentsList], newSegmentsList)
        queryClient.invalidateQueries([QUERY_KEY.segmentsList])
      }

      queryClient.invalidateQueries(queryKey)
    }
  })

  const segment = useMemo(() => (data ? convertSegmentAPIToUI(data.data) : undefined), [data])

  return {
    isLoading: isInitialLoading,
    data: segment,
    error,
    refetch: refetch as any,
    updateSegment,
    isUpdateSegmentLoading
  }
}
