import { type ModalState } from './modal.reducer'
import { type ModalType } from './modal.type'

export const selectIsModalOpen =
  (modalType: ModalType) =>
  (state: ModalState): boolean => {
    return state.type === modalType
  }

export const selectLocationOnDone = (state: ModalState): string | undefined => {
  return state.locationOnDone
}
