import { type FlowUI } from '@ghostmonitor/recartapis'
import { useMutation } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { api } from '../../utils/api'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_KEY } from './query-keys'

type PatchFlowPayload = Pick<FlowUI, 'name'>

async function patch(flowId: string, payload: PatchFlowPayload) {
  await api.patchFlow(flowId, payload)
}

export function useFlow(flowId?: string | null): UseResource<FlowUI> {
  const queryKey = useMemo(() => [QUERY_KEY.flow, flowId], [flowId])
  const { handleSettled, handleError } = useMutationHelpers(queryKey)
  const { isLoading, data: flows, handlePatchMutate: handlePatchMutateFlows } = hooks.useFlows()

  const handlePatchMutate = useCallback(
    (patchPayload: Pick<FlowUI, 'name'>) => {
      if (!flowId) {
        throw new Error('Flow ID is required')
      }
      handlePatchMutateFlows({ id: flowId, payload: patchPayload })
    },
    [flowId, handlePatchMutateFlows]
  )

  const { mutateAsync: mutatePatchFlow } = useMutation<void, unknown, Partial<FlowUI>>({
    mutationFn: (payload: PatchFlowPayload) => patch(flowId, payload),
    onMutate: handlePatchMutate,
    onSettled: handleSettled,
    onError: handleError
  })

  return {
    data: flows?.find((flow) => flow._id === flowId),
    isLoading,
    patch: mutatePatchFlow
  }
}
