import {
  type BaseSequenceItemAPI,
  convertSequenceItemAPIToSequenceItemUI,
  type SequenceItemUI
} from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useSequenceItem(sequenceItemId?: string | null): UseResource<SequenceItemUI> {
  const queryKey = [QUERY_KEY.sequenceItem, sequenceItemId]

  const { isInitialLoading, data: sequenceItem } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<BaseSequenceItemAPI>(`sequence-item/${sequenceItemId}`)
    },
    enabled: sequenceItemId !== null && sequenceItemId !== undefined
  })

  return {
    data: sequenceItem && convertSequenceItemAPIToSequenceItemUI(sequenceItem),
    isLoading: isInitialLoading
  }
}
