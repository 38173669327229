import { type SubscriberListResponse } from '@ghostmonitor/recartapis'
import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { SubscribersListFilterContext } from '../../routes/subscribers/components/filters/subscribers-list-filter.context'
import { request } from '../../utils/request'
import { QUERY_KEY } from './query-keys'

const filterMapping = {
  channels: 'channel',
  sources: 'source',
  languages: 'locale'
}
const PAGE_SIZE = 10
interface SubscriberListQueryParams {
  limit?: number
  offset?: number
  search?: string
  source?: string[]
  locale?: string[]
  channel?: string[]
}

function splitMergedFilterValues(values: string[]) {
  return values.flatMap((value: string) => {
    if (value.includes(',')) {
      return value.split(',')
    } else {
      return value
    }
  })
}

function mapFiltersToQueryParams(filters: SubscriberListQueryParams) {
  if (!filters) {
    return
  }

  return Object.entries(filters).reduce((queryParams, [key, filter]) => {
    const param = filterMapping[key]

    if (param) {
      queryParams[param] = param === 'source' ? splitMergedFilterValues(filter) : filter
    } else {
      queryParams[key] = filter
    }

    return queryParams
  }, {})
}

export function useSubscribersList(segmentId?: string) {
  const { currentPage, search, categories } = useContext(SubscribersListFilterContext)

  const params: SubscriberListQueryParams = {
    limit: PAGE_SIZE,
    offset: (currentPage - 1) * PAGE_SIZE,
    ...{ search: search !== '' ? encodeURIComponent(search) : undefined },
    ...categories,
    ...(segmentId && { segmentId })
  }

  const queryKey = [QUERY_KEY.subscribersList, params]

  const { isLoading, data, isError, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<SubscriberListResponse>('subscribers', {
        params: mapFiltersToQueryParams(params)
      })
    }
  })

  return {
    isLoading,
    data: data?.data,
    count: data?.count,
    isError,
    refetch
  }
}
