import { type OptinToolExperiment } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useOptinToolExperiments(): UseResource<OptinToolExperiment[]> {
  const queryKey = [QUERY_KEY.optinToolExperiments]

  const {
    data: optinToolExperimentsData,
    isLoading,
    error
  } = useQuery({
    queryKey,
    queryFn: api.getOptinToolExperiments
  })

  return {
    data: optinToolExperimentsData?.data,
    error,
    isLoading
  }
}
