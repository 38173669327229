import { type SubscriberOverview, type SubscriberOverviewResponse } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import type { UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useSubscribersOverview(): UseResource<SubscriberOverview> {
  const queryKey = [QUERY_KEY.subscribersOverview]

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<SubscriberOverviewResponse>('subscribers-overview')
    }
  })

  return { isLoading, data: data?.data }
}
