import { type HttpResponse, type Site } from '@ghostmonitor/recartapis'
import {
  type QueryObserverResult,
  type RefetchOptions,
  type UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { type SiteState } from '../../store/slices/me/me.types'
import { type DeepPartial } from '../../types/utils.type'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_KEY } from './query-keys'
import { useFacebookPage } from './use-facebook-page'

export function useSite(options?: UseQueryOptions<HttpResponse<Site>>): UseResource<
  SiteState,
  DeepPartial<Site>
> & {
  patchIntegrationEvent: (payload: {
    slug: string
    optinType: 'one-way' | 'two-way'
  }) => Promise<void>
} {
  const queryKey = [QUERY_KEY.site]
  const { handlePatch, handleSettled, handleError } = useMutationHelpers(queryKey)
  const { data: facebookPage, isLoading: isFacebookPageLoading } = useFacebookPage()

  const {
    isLoading: isSiteLoading,
    data: site,
    isError,
    refetch
  } = useQuery({
    queryKey,
    queryFn: () => api.getSite(),
    ...options
  })
  const queryClient = useQueryClient()

  const isLoading = isSiteLoading || isFacebookPageLoading

  const siteState: SiteState & { _id: string } = site?.data
    ? {
        ...site.data,
        _id: site.data.id,
        fbMessengerConfig: facebookPage ?? null
      }
    : undefined

  async function patch(payload: DeepPartial<Site>) {
    const newSite = await api.patchSite(payload)
    queryClient.setQueryData(queryKey, newSite)
  }

  async function patchSiteIntegrationEvent(payload: {
    slug: string
    optinType: 'one-way' | 'two-way'
  }) {
    await api.patchSiteIntegrationEvent(payload.slug, payload.optinType)
  }

  const { mutateAsync: patchSite } = useMutation<void, unknown, DeepPartial<Site>>({
    mutationFn: patch,
    onMutate: handlePatch,
    onSettled: handleSettled,
    onError: handleError
  })

  const { mutateAsync: patchIntegrationEvent } = useMutation<void, unknown, unknown>({
    mutationFn: patchSiteIntegrationEvent,
    onMutate: handlePatch,
    onSettled: handleSettled,
    onError: handleError
  })

  return {
    data: siteState,
    patch: patchSite,
    patchIntegrationEvent,
    refetch: refetch as (
      options?: RefetchOptions
    ) => Promise<QueryObserverResult<SiteState, unknown>>,
    isError,
    isLoading
  }
}
