import { type HttpResponse, type SMSConversations } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useSMSConversations(): UseResource<SMSConversations.SmsConversation[]> {
  const queryKey = [QUERY_KEY.smsConversations]

  const { isLoading, data, error } = useQuery<
    HttpResponse<SMSConversations.SmsConversation[]>,
    AxiosError
  >({
    queryKey,
    queryFn: api.getSMSConversations,
    refetchInterval: 10000
  })

  return {
    isLoading,
    data: data?.data,
    error
  }
}
