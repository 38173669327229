import { SequenceTag, type Stat } from '@ghostmonitor/recartapis'
import { type QueryFunction, type QueryKey, useQuery } from '@tanstack/react-query'
import type moment from 'moment'
import { useContext, useMemo } from 'react'
import { queryClient } from '../../components/app/query-client'
import {
  getDefaultTimeFrameFilter,
  TimeFrameFilterContext
} from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export interface SequenceGroupStatsBySlug {
  [sequenceGroupSlug: string]: Stat
}

interface SequenceGroupStatQueryParams {
  startDate?: string
  endDate?: string
  tags?: SequenceTag | SequenceTag[]
}

interface SequenceGroupStatProps {
  isEnabled: boolean
}

function getQueryKey(queryParams: SequenceGroupStatQueryParams): QueryKey {
  return [QUERY_KEY.sequenceGroupsStat, queryParams]
}

function buildQuery(
  dateFrom: moment.Moment | null,
  dateTo: moment.Moment | null,
  defaultQueryParams: Partial<SequenceGroupStatQueryParams> = {}
): SequenceGroupStatQueryParams {
  const queryParams: SequenceGroupStatQueryParams = {
    ...defaultQueryParams
  }

  if (dateFrom) {
    queryParams.startDate = dateFrom.toISOString()
  }

  if (dateTo) {
    queryParams.endDate = dateTo.toISOString()
  }

  return queryParams
}

function fetchSequenceGroupStats(
  queryParams: SequenceGroupStatQueryParams
): QueryFunction<SequenceGroupStatsBySlug> {
  return async () =>
    request.get<SequenceGroupStatsBySlug>(`statistics/sequence-groups`, {
      params: queryParams
    })
}

function useSequenceGroupSMSStats(
  { isEnabled }: SequenceGroupStatProps = { isEnabled: true }
): UseResource<SequenceGroupStatsBySlug> {
  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)
  const queryParams = buildQuery(dateFrom, dateTo, { tags: SequenceTag.SMS })
  const queryKey = getQueryKey(queryParams)

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: fetchSequenceGroupStats(queryParams),
    enabled: isEnabled
  })

  const sequenceGroupStats = useMemo<SequenceGroupStatsBySlug>(() => {
    if (!data) {
      return {}
    }
    const parsedSMSSequenceGroupStats = {}
    Object.keys(data).forEach((key) => {
      parsedSMSSequenceGroupStats[`sms-${key}`] = data[key]
    })

    return parsedSMSSequenceGroupStats
  }, [data])

  return {
    data: sequenceGroupStats,
    isLoading
  }
}

function useSequenceGroupFbMessageStats(
  { isEnabled }: SequenceGroupStatProps = { isEnabled: true }
): UseResource<SequenceGroupStatsBySlug> {
  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)
  const queryParams = buildQuery(dateFrom, dateTo, { tags: SequenceTag.FBMESSAGE })
  const queryKey = getQueryKey(queryParams)

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: fetchSequenceGroupStats(queryParams),
    enabled: isEnabled
  })

  return { data, isLoading }
}

function prefetchSequenceGroupSMSStats(): void {
  const { dateFrom, dateTo } = getDefaultTimeFrameFilter()
  const queryParams = buildQuery(dateFrom, dateTo, { tags: SequenceTag.SMS })
  const queryKey = getQueryKey(queryParams)

  queryClient.prefetchQuery(queryKey, fetchSequenceGroupStats(queryParams))
}

function prefetchSequenceGroupFbMessageStats(): void {
  const { dateFrom, dateTo } = getDefaultTimeFrameFilter()
  const queryParams = buildQuery(dateFrom, dateTo, { tags: SequenceTag.FBMESSAGE })
  const queryKey = getQueryKey(queryParams)

  queryClient.prefetchQuery(queryKey, fetchSequenceGroupStats(queryParams))
}

export function useSequenceGroupStats(
  props: SequenceGroupStatProps = { isEnabled: true }
): UseResource<SequenceGroupStatsBySlug> & { prefetch: () => void } {
  const { isLoading: SMSSequenceGroupStatsIsLoading, data: SMSSequenceGroupStats } =
    useSequenceGroupSMSStats(props)
  const { isLoading: fbMessageSequenceGroupStatsIsLoading, data: fbMessageSequenceGroupStats } =
    useSequenceGroupFbMessageStats(props)

  function prefetch() {
    prefetchSequenceGroupSMSStats()
    prefetchSequenceGroupFbMessageStats()
  }

  return {
    data: { ...SMSSequenceGroupStats, ...fbMessageSequenceGroupStats },
    isLoading: SMSSequenceGroupStatsIsLoading || fbMessageSequenceGroupStatsIsLoading,
    prefetch
  }
}
