import {
  type CreateSubscriberDetailPayload,
  type HttpResponse,
  type PatchSubscriberDetailPayload,
  type SubscriberDetail
} from '@ghostmonitor/recartapis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { api } from '../../utils/api'
import { QUERY_KEY } from './query-keys'

export function useSubscriberDetailsCreate() {
  const queryKey = useMemo(() => [QUERY_KEY.subscriberDetails], [])
  const queryClient = useQueryClient()

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryKey)
  }, [queryClient, queryKey])

  // CREATE
  const {
    mutateAsync: createSubscriberDetail,
    isLoading: isCreatingSubscriberDetail,
    error: createSubscriberDetailError
  } = useMutation<HttpResponse<SubscriberDetail>, unknown, CreateSubscriberDetailPayload>({
    mutationFn: api.createSubscriberDetail,
    onSettled: handleSettled
  })

  // ADD OPTION
  const {
    mutateAsync: updateSubscriberDetail,
    isLoading: isUpdatingSubscriberDetail,
    error: updateSubscriberDetailError
  } = useMutation<HttpResponse<SubscriberDetail>, unknown, PatchSubscriberDetailPayload>({
    mutationFn: api.updateSubscriberDetail,
    onSettled: handleSettled
  })

  return {
    createSubscriberDetail,
    isCreatingSubscriberDetail,
    createSubscriberDetailError,
    updateSubscriberDetail,
    isUpdatingSubscriberDetail,
    updateSubscriberDetailError
  }
}
