import {
  type GetMediaUploadsResponse,
  type HttpResponse,
  type MediaUpload,
  type PatchMediaUploadResponse
} from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { config } from '../../config'
import { selectSiteId } from '../../store/slices/site/site.selectors'
import { logger } from '../../utils/logger/logger'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { useSiteSelector } from '../use-site-selector'
import { QUERY_KEY } from './query-keys'

export function useMediaUploads(
  source: string,
  limit: number,
  enabled: boolean
): UseResource<MediaUpload[]> & {
  patchMediaUpload: (id: string) => Promise<PatchMediaUploadResponse>
  deleteMediaUpload: (id: string) => Promise<HttpResponse>
} {
  const queryClient = useQueryClient()
  const queryKey = [QUERY_KEY.mediaUploads, source, limit]
  const siteId = useSiteSelector(selectSiteId)

  const { data, isInitialLoading, isError } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<GetMediaUploadsResponse>(
        `${config.UPLOAD_API_URL}sites/${siteId}/media-uploads/recent`,
        {
          params: {
            limit,
            source
          }
        }
      )
    },
    enabled
  })

  const { mutateAsync: patchMediaUpload } = useMutation({
    mutationFn: async (id: string) => {
      return request.patch<PatchMediaUploadResponse>(
        `${config.UPLOAD_API_URL}sites/${siteId}/media-uploads/${id}`
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
    },
    onError: () => {
      logger.error('Failed to patch media upload')
    }
  })

  const { mutateAsync: deleteMediaUpload } = useMutation({
    mutationFn: async (id: string) => {
      return request.del<HttpResponse>(
        `${config.UPLOAD_API_URL}sites/${siteId}/media-uploads/${id}`
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
    },
    onError: () => {
      logger.error('Failed to delete media upload')
    }
  })

  return {
    data: data?.data,
    isLoading: isInitialLoading,
    isError,
    patchMediaUpload,
    deleteMediaUpload
  }
}
