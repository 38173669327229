import { type SMSSettingsService, type SMSSettings } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useSMSTimezoneDeviation(): UseResource<SMSSettings.TimezoneDeviation> {
  const queryKey = [QUERY_KEY.smsTimezoneDeviation]

  const {
    isLoading,
    data: timezoneDeviation,
    isError
  } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<SMSSettingsService.GetTimezoneDeviationResponse>(
        'sms-settings/timezone-deviation'
      )
    }
  })

  return {
    data: timezoneDeviation?.data,
    isLoading,
    isError
  }
}
