import {
  type DuplicateSegmentRequest,
  type DuplicateSegmentResponse,
  type SegmentListResponse
} from '@ghostmonitor/recartapis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { QUERY_KEY } from './query-keys'

export function useSegmentListDuplicate() {
  const queryClient = useQueryClient()

  const { mutateAsync: duplicateSegment, isLoading: isDuplicateSegmentLoading } = useMutation<
    DuplicateSegmentResponse,
    unknown,
    DuplicateSegmentRequest & { segmentId: string }
  >({
    mutationFn: ({ segmentId, name, type }) => {
      const segment: DuplicateSegmentRequest = {
        name,
        type
      }

      return request.post<DuplicateSegmentResponse>(`segments/${segmentId}/duplicate`, segment)
    },
    onSuccess: (duplicateSegmentResponse: DuplicateSegmentResponse) => {
      const listQueryKey = [QUERY_KEY.segmentsList, null]
      const segmentsList: SegmentListResponse = queryClient.getQueryData(listQueryKey)
      if (segmentsList) {
        const newSegmentsList = {
          data: [...segmentsList.data, duplicateSegmentResponse.data]
        }

        queryClient.setQueryData(listQueryKey, newSegmentsList)
        queryClient.invalidateQueries(listQueryKey)
      }
    }
  })

  return { duplicateSegment, isDuplicateSegmentLoading }
}
