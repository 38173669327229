import {
  type BillingSubscription,
  type GetBillingSubscriptionsResponse
} from '@ghostmonitor/recartapis'
import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

type UseBillingSubscriptionsOptions = UseQueryOptions<GetBillingSubscriptionsResponse>

type UseBillingSubscriptions = UseResource<BillingSubscription[]>

export function useBillingSubscriptions(
  options?: UseBillingSubscriptionsOptions
): UseBillingSubscriptions {
  const queryKey = [QUERY_KEY.billingSubscriptions]

  const {
    isLoading,
    data: billingSubscriptions,
    isError,
    refetch
  } = useQuery({
    queryKey,
    queryFn: api.getBillingSubscriptions,
    ...options
  })

  return {
    data: billingSubscriptions?.data,
    isLoading,
    isError,
    refetch: refetch as any
  }
}
