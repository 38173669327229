import { type GetSubdomainsResponse, type SubdomainAvailability } from '@ghostmonitor/recartapis'
import { type AxiosError } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useSubdomainAvailability(
  subdomain: string | undefined
): UseResource<SubdomainAvailability> {
  const queryKey = [QUERY_KEY.subdomainsAvailability, subdomain]

  const {
    data,
    isInitialLoading,
    isError,
    error: requestError
  } = useQuery<GetSubdomainsResponse, AxiosError<void>>({
    queryKey,
    queryFn: async () => api.getSubdomains(subdomain),
    enabled: subdomain !== undefined && subdomain !== ''
  })

  return {
    isLoading: isInitialLoading,
    isError,
    data: data?.data,
    requestError
  }
}
