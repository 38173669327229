import { useQuery } from '@tanstack/react-query'
import { type Achievement } from '../../types/achievement.type'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useAchievements(): UseResource<Achievement[]> {
  const { isLoading, data } = useQuery({
    queryKey: [QUERY_KEY.achievements],
    queryFn: async () => {
      return request.get<Achievement[]>('me/achievements')
    }
  })

  return {
    data,
    isLoading
  }
}
