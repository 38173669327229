import { type GetApiKeyResponse } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { QUERY_KEY } from './query-keys'

export function useApiKey(id: string) {
  const queryKey = [QUERY_KEY.apiKeys, id]

  const { data, isInitialLoading, error } = useQuery({
    queryKey,
    queryFn: () => request.get<GetApiKeyResponse>(`apikeys/${id}`),
    enabled: !!id
  })

  return {
    data: data?.data.key,
    isLoading: isInitialLoading,
    error
  }
}
