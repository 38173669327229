import {
  type OptinTool,
  type OptinToolListResponse,
  type OptinToolResponse,
  type UpdateOptinToolRequest,
  type UpdateOptinToolResponse
} from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { useDispatch } from 'react-redux'
import { config } from '../../config'
import { LandingPageMenuItemSlug } from '../../routes/optin-tools/landing-page/editor/menu-items'
import { type AppDispatch } from '../../store/create-store'
import { updateErrorMetaField } from '../../store/slices/optin-tool-editor/optin-tool-editor.reducer'
import { Notification } from '../../utils/notification/notification.util'
import { request } from '../../utils/request'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useOptinTool<TOptinTool extends OptinTool>(
  optinToolId?: string
): UseResource<TOptinTool> & {
  updateOptinTool: (optinTool: OptinTool) => Promise<UpdateOptinToolResponse>
  isUpdateOptinToolLoading: boolean
} {
  const dispatch: AppDispatch = useDispatch()
  const queryClient = useQueryClient()
  const queryKey = [QUERY_KEY.optinTool, optinToolId]

  const { data: site } = hooks.useSite()

  const { isInitialLoading, data, error, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<OptinToolResponse>(`optin-tools/${optinToolId}`)
    },
    enabled: optinToolId !== undefined
  })

  const {
    mutateAsync: updateOptinTool,
    isLoading: isUpdateOptinToolLoading,
    error: updateOptinToolError
  } = useMutation({
    mutationFn: (optinTool: OptinTool) => {
      return request.put<UpdateOptinToolResponse>(`optin-tools/${optinTool.id}`, optinTool)
    },
    onSuccess: (updateOptinToolResponse: UpdateOptinToolResponse) => {
      const optinToolList: OptinToolListResponse = queryClient.getQueryData([
        QUERY_KEY.optinToolsList
      ])
      if (optinToolList) {
        const newOptinToolList = {
          data: optinToolList.data.map((optinTool) =>
            optinTool.id === optinToolId ? updateOptinToolResponse.data : optinTool
          )
        }
        queryClient.setQueryData([QUERY_KEY.optinToolsList], newOptinToolList)
        queryClient.invalidateQueries([QUERY_KEY.optinToolsList])
        queryClient.invalidateQueries([QUERY_KEY.optinTool])
      }
      queryClient.invalidateQueries(queryKey)
    },
    onError: (error: AxiosError) => {
      let message: string
      switch (error.name) {
        // this case is going to be deprecated as soon as we release the backend change to use the codes below
        case 'Url is already taken':
          message = 'This URL is in use by an other page'
          dispatch(
            updateErrorMetaField({
              menuSlug: LandingPageMenuItemSlug.SETTINGS,
              accordionSlug: 'displayRules',
              fieldName: 'link',
              value: { errorMessage: message }
            })
          )

          Notification.error(
            message,
            'Provide a different URL or change the URL of the original site.'
          )
          break
        case 'ShopifyPageHandleTaken':
          message = 'This URL is in use by an other page'
          dispatch(
            updateErrorMetaField({
              menuSlug: LandingPageMenuItemSlug.SETTINGS,
              accordionSlug: 'displayRules',
              fieldName: 'link',
              value: { errorMessage: message }
            })
          )
          Notification.error(
            message,
            'Provide a different URL or change the URL of the original site.'
          )
          break
        case 'ShopifyPermissionError':
          message = 'Could not create page due to Shopify permission error'
          dispatch(
            updateErrorMetaField({
              menuSlug: LandingPageMenuItemSlug.SETTINGS,
              accordionSlug: 'displayRules',
              fieldName: 'link',
              value: { errorMessage: message }
            })
          )
          Notification.error(
            message,
            <div>
              Your Shopify store permission for the Recart app is not up to date. Please log in
              again from the Shopify admin{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href={`${config.SHOPIFY_AUTH_URL}authurl?shop=${site.shopify.shop}`}
              >
                here
              </a>
              .
            </div>
          )
          break
        default:
          Notification.error('Something went wrong. Please try again or contact support.')
      }
    }
  })

  return {
    isLoading: isInitialLoading,
    data: data?.data as TOptinTool,
    error,
    refetch: refetch as any,
    updateOptinTool,
    isUpdateOptinToolLoading,
    updateOptinToolError
  }
}
