import { type HttpResponse, type OrderService } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useOrderTags(
  subscriberId: string
): UseResource<OrderService.GetTagsResponse['data']> {
  const queryKey = [QUERY_KEY.orderTags, subscriberId]

  const { isLoading, data } = useQuery<
    HttpResponse<OrderService.GetTagsResponse['data']>,
    AxiosError
  >({
    queryKey,
    queryFn: async () =>
      request.get<HttpResponse<OrderService.GetTagsResponse['data']>>(
        `subscribers/${subscriberId}/order-tags`
      )
  })

  return {
    isLoading,
    data: data?.data
  }
}
