import { type HttpResponse, type SMSConversations } from '@ghostmonitor/recartapis'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export interface UseSMSConversation extends UseResource<SMSConversations.SmsConversationMessage[]> {
  sendMessage: (text: string) => Promise<void>
}

export function useSMSConversation(phoneNumber: string | undefined): UseSMSConversation {
  const queryKey = useMemo(() => [QUERY_KEY.smsConversations, phoneNumber], [phoneNumber])
  const queryClient = useQueryClient()

  const { isInitialLoading, data, error } = useQuery<
    HttpResponse<SMSConversations.SmsConversationMessage[]>,
    AxiosError
  >({
    queryKey,
    queryFn: () => api.getSMSConversation(phoneNumber),
    enabled: phoneNumber !== undefined,
    refetchInterval: 5000
  })

  const sendMessage = useCallback(
    async (text: string) => {
      await api.sendSMSMessage(phoneNumber, text)
      queryClient.invalidateQueries(queryKey)
    },
    [phoneNumber, queryClient, queryKey]
  )

  return {
    isLoading: isInitialLoading,
    data: data?.data,
    sendMessage,
    error
  }
}
