import { useQuery } from '@tanstack/react-query'
import type { TemplatesPopularity } from '../../types/template/templates-popularity.type'
import { request } from '../../utils/request'
import type { UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useTemplatesPopularity(): UseResource<TemplatesPopularity> {
  const queryKey = [QUERY_KEY.templatesPopularity]

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<TemplatesPopularity>('/templates/popularity')
    }
  })

  return {
    data,
    isLoading
  }
}
