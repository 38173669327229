import { type SMSSettings, type SMSSettingsService } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { useMutation, useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_KEY } from './query-keys'

export type PatchSMSSmartSendingSettingRequest = Omit<
  SMSSettingsService.UpsertSmartSendingSettingsRequest,
  'siteId'
>

async function patchSMSSmartSendingSettings(
  patchPayload: PatchSMSSmartSendingSettingRequest
): Promise<void> {
  await api.patchSMSSmartSendingSettings(patchPayload)
}

export function useSMSSmartSendingSettings(
  options?: UseQueryOptions<SMSSettingsService.GetSmartSendingSettingsResponse>
): UseResource<SMSSettings.SmartSendingConfig> {
  const queryKey = [QUERY_KEY.smsSmartSendingSettings]
  const { handleError, handlePatch, handleSettled } =
    useMutationHelpers<PatchSMSSmartSendingSettingRequest>(queryKey)

  const {
    isLoading,
    data,
    error: requestError
  } = useQuery({
    queryKey,
    queryFn: api.getSMSSmartSendingSettings,
    ...options
  })
  const error: SerializedError = requestError

  const { mutateAsync: mutatePatchSMSSmartSendingSettings } = useMutation<
    void,
    unknown,
    PatchSMSSmartSendingSettingRequest
  >({
    mutationFn: patchSMSSmartSendingSettings,
    onMutate: handlePatch,
    onError: handleError,
    onSettled: handleSettled
  })

  return {
    data: data?.data,
    patch: mutatePatchSMSSmartSendingSettings,
    error,
    isLoading
  }
}
