import { type AppAuth } from '@ghostmonitor/recartapis'
import { useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  type IntegrationSlug,
  type UpdateIntegrationPayload
} from '../../routes/integrations/integrations.type'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_KEY } from './query-keys'

export function useIntegration<TIntegration = AppAuth>(
  slug: IntegrationSlug
): UseResource<TIntegration, UpdateIntegrationPayload> {
  const queryKey = useMemo(() => [QUERY_KEY.integration, slug], [slug])

  const { handleError, handleSettled, handlePatch, handleRemove } =
    useMutationHelpers<UpdateIntegrationPayload>(queryKey)

  const { isLoading, isFetching, data } = useQuery({
    queryKey,
    queryFn: () => request.get<TIntegration>(`integrations/${slug}`)
  })

  async function patchIntegration(integration: UpdateIntegrationPayload): Promise<void> {
    // The HTTP method can be misleading here, because here the patch actually does upsert
    await request.patch<TIntegration>(`integrations/${slug}`, integration)
  }

  async function removeIntegration(): Promise<void> {
    await request.del<TIntegration>(`integrations/${slug}`)
  }

  const { mutateAsync: mutatePatchIntegration } = useMutation<
    void,
    unknown,
    UpdateIntegrationPayload
  >({
    mutationFn: patchIntegration,
    onMutate: handlePatch,
    onError: handleError,
    onSettled: handleSettled
  })

  const { mutateAsync: mutateRemoveIntegration } = useMutation<void, unknown, unknown>({
    mutationFn: removeIntegration,
    onMutate: handleRemove,
    onSettled: handleSettled,
    onError: handleError
  })

  return {
    data,
    patch: mutatePatchIntegration,
    remove: mutateRemoveIntegration,
    isLoading,
    isFetching
  }
}
