import { type TargetRulesBlast } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import {
  type SubscribersCount,
  type SubscribersCountData
} from '../../types/subscribers-count.type'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useSubscribersCount(
  channel: string,
  targetRules?: TargetRulesBlast,
  isEnabled = true
): UseResource<SubscribersCountData> & { isLoading: boolean } {
  const queryKey = [QUERY_KEY.subscribersCount, channel, targetRules]

  const {
    isInitialLoading,
    isError,
    error,
    data: subscribersCount
  } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<SubscribersCount>(`/subscribers/sms-subscribers/count`, {
        params: {
          ...(targetRules?.includeSegmentIds && {
            includeSegmentIds: targetRules.includeSegmentIds
          }),
          ...(targetRules?.excludeSegmentIds && {
            excludeSegmentIds: targetRules.excludeSegmentIds
          })
        }
      })
    },
    enabled: isEnabled
  })

  return {
    data: subscribersCount?.data,
    isError,
    error,
    isLoading: isInitialLoading
  }
}
