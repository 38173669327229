import {
  type SearchProductImagesRequest,
  type SearchProductImagesResponse
} from '@ghostmonitor/recartapis'
import { useInfiniteQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { QUERY_KEY } from './query-keys'

export function useProductImages(
  queryParams?: Omit<
    SearchProductImagesRequest,
    | 'siteId'
    | ('offset' & {
        offset?: number
      })
  >
) {
  async function fetchProductImages({ pageParam = 0 }): Promise<SearchProductImagesResponse> {
    const params = {
      ...queryParams,
      offset: pageParam
    }

    return request.post<SearchProductImagesResponse>('product-images/search', { ...params })
  }

  const queryKey = [QUERY_KEY.productImages, queryParams?.name]

  const { isLoading, data, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: fetchProductImages,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.length < queryParams.limit || lastPage.data.length === 0) {
        return undefined
      }

      return pages.flatMap((page) => page.data).length
    }
  })

  return {
    isLoading,
    data: data?.pages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  }
}
