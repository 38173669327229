import {
  type GetStaticDiscountCodeResponse,
  type StaticDiscountCode
} from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { type AxiosError } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { SequenceEditorError } from '../../routes/SequenceEditor/types/sequence-editor-errors'
import { api } from '../../utils/api'
import { serializedError } from '../../utils/serialized-error'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useDiscountCode(
  discountCode: string,
  isEnabled = true
): UseResource<StaticDiscountCode> {
  const queryKey = [QUERY_KEY.discountCodes, discountCode]
  let {
    isInitialLoading,
    isError,
    data,
    error: requestError
  } = useQuery<GetStaticDiscountCodeResponse, AxiosError<GetStaticDiscountCodeResponse>>({
    queryKey,
    queryFn: async () => api.getDiscountCode(discountCode),
    enabled: discountCode !== undefined && discountCode !== '' && isEnabled,
    staleTime: 10000
  })

  let error: SerializedError = requestError
  if (!isInitialLoading && data?.data?.expired) {
    isError = true
    error = serializedError<SequenceEditorError>(SequenceEditorError.DiscountCodeExpired)
  }

  if (!isInitialLoading && requestError?.response?.status === 404) {
    error = serializedError<SequenceEditorError>(SequenceEditorError.DiscountCodeNotExists)
  }
  if (!isInitialLoading && requestError && requestError?.response?.status >= 500) {
    error = serializedError<SequenceEditorError>(SequenceEditorError.DiscountCodeError)
  }

  return {
    data: data?.data,
    isLoading: isInitialLoading,
    isError,
    error
  }
}
