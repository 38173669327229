import { type Billing, type BillingService } from '@ghostmonitor/recartapis'
import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useCurrentBillingPeriods(
  options?: UseQueryOptions<BillingService.GetBillingPeriodsResponse>
): UseResource<Billing.BillingPeriod[]> {
  const queryKey = [QUERY_KEY.currentBillingPeriods]

  const {
    data: billingPeriods,
    isLoading,
    isError
  } = useQuery({
    queryKey,
    queryFn: api.getCurrentBillingPeriods,
    ...options
  })

  return {
    data: billingPeriods?.data,
    isLoading,
    isError
  }
}
